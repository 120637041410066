import axios from "axios";

import { useEffect, useState } from "react";

function App() {
  const [fileUrl, setFileUrl] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios("https://aws.random.cat/meow");
      setFileUrl(result.data.file);
    };

    fetchData();
  }, []);

  console.log(fileUrl);
  return (
    fileUrl && (
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          display: "flex"
        }}
      >
        <img
          style={{
            margin: "auto"
          }}
          height="1000"
          src={fileUrl}
        ></img>
      </div>
    )
  );
}

export default App;
